import { useWebStore } from '@/store/web.js'
import { useRoute } from '#app';
export default function () {
  const store = useWebStore();
  const route = useRoute();
  console.log(store.sites)
  console.log(store.chosenTab)
    useHead({
      title: store.titleBase + store.sites.find(site=> site.url === store.chosenTab).text,
      meta: [
        {
          name: store.sites.find(site=> site.url === store.chosenTab).metaName,
          content: store.sites.find(site=> site.url === store.chosenTab).metaContent,
        },
      ],
      link: [
        { rel: 'canonical', href: `https://poliol.hu${route.path}` }
      ],
  });
}
